const ApiUrl = (e) => {
    let app_api_url;
    if (process.env.NODE_ENV === 'development') {
        app_api_url = 'http://elxapi.my/api/v3/admin'
    }
    if (process.env.NODE_ENV === 'production') {
        app_api_url = 'https://apis.elibrax.com/api/v3/admin'
        //app_api_url = 'https://apis.defiwares.com/api/v3/admin'
    }
    return app_api_url;
}
export default ApiUrl;