import * as React from "react";
import Switch from '@material-ui/core/Switch';
import { SelectArrayInput } from 'react-admin';
import JWT from "jsonwebtoken";

import {
    Edit,
    SelectInput,
    FormWithRedirect,
    TextInput,
    SaveButton,
    DeleteButton,
    Link
} from 'react-admin';
import { Box, Card, CardContent, Typography, Toolbar, Grid, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { List, ListActions } from '@react-admin/ra-enterprise';
import ExchangePairField from "./Ctitles/ExchangePairField";

const payments_choices = [
    { id: 'manual', name: 'Manual' },
    { id: 'semiautomatic', name: 'Semiautomatic' },
    { id: 'automatic', name: 'Automatic' }]

const pairtype_choices = [
    { id: 'buy', name: 'Buy pair' },
    { id: 'sell', name: 'Sell pair' },
    { id: 'exchange', name: 'Exchange pair' }]

const bestprice_choices = [
    { id: 'buy_price', name: 'Buy price' },
    { id: 'sell_price', name: 'Sell price' }]

const orgfeetype_choices = [
    { id: '-', name: 'Minus(-)' },
    { id: '+', name: 'Plus(+)' }]

const useStyles = makeStyles({
    ownfont14: { fontSize: '14px', paddingBottom: '7px' },
    ownfont12: { fontSize: '12px' },
    reactSwitch: { pointerEvents: 'none' },
    paddingTop: { paddingTop: '0px' },
    fweight500: { fontWeight: 500 },
    googleFa: { fontSize: '1rem', fontWeight: 500 }
});

const ControlledSwitches = (status_props) => {
    let id = status_props.id;
    let default_status = (status_props.current_status === true) ? true : false;
    let [checked, setChecked] = React.useState(default_status);
    const handleChange = (event) => {
        let login_token = localStorage.getItem('auth');
        setChecked(event.target.checked);
        let new_status = event.target.checked;
        let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
            algorithm: "HS512",
        });
        const request = new Request(api_url() + '/statusUpdateSellPair', {
            method: 'POST',
            body: JSON.stringify({ id, new_status }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${login_token}`,
                'xelxtoken': apitoken
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
            })
            .catch((error) => {
                throw new Error(error);
            });

    };
    return (
        <Switch
            checked={checked}
            onClick={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

const EditSellPairForm = (props) => {
    const classes = useStyles();
    return (
        <FormWithRedirect {...props} render={formProps => (
            <div>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box flex={1} mr="1em">
                                        Base Currency
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr="1em">
                                        <TextInput className="tofrompair_input" disabled source="from_pair" resource="exchangepair" label="Currency" fullWidth />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box flex={1} mr="1em">
                                        Quote Currency
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mr="1em">
                                        <TextInput className="tofrompair_input" disabled source="to_pair" resource="exchangepair" label="Currency" fullWidth />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardContent>
                    <Grid container>

                        <Grid item xs={12}>
                            <Box flex={1} mr="1em">
                                <Grid container style={{ marginBottom: '10px' }}>
                                    <Grid item xs={6} p="0">
                                        <Box flex={1} mr="1em">
                                            Settings
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box flex={1}>
                                            <Grid container>
                                                <Grid item xs={9}><Box flex={1} textAlign="right">Status</Box></Grid>
                                                <Grid item xs={3} style={{ marginTop: '-10px' }}>
                                                    <Box flex={1} textAlign="right">
                                                        {(props.record && props.record.status !== undefined) ?
                                                            <ControlledSwitches current_status={props.record.status} id={props.record.id} />
                                                            : ''}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Box mr="1em">
                                            <TextInput source="orginal_fee" resource="buypair" label="Orginal fee" fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box mr="1em">
                                            <SelectInput source="orginal_fee_type" resource="buypair" label="Orginal fee type" fullWidth choices={orgfeetype_choices} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box mr="1em">
                                            <TextInput source="precision" resource="buypair" label="Fee precision" fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box>
                                            <SelectInput source="system_process_by" resource="buypair" label="Payment method" fullWidth choices={payments_choices} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box flex={1} mr="1em">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Box mr="1em">
                                            <TextInput source="grate" resource="buypair" label="Expire time" fullWidth />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Box mr="1em">
                                            <SelectInput source="best_price" resource="buypair" label="Best price" fullWidth choices={bestprice_choices} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box>
                                            <TextInput source="positions" resource="buypair" label="Position" fullWidth />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box flex={1} mr="1em" mt="1em">
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <Link to={'/sellpair?filter={"type":"sellpair"}'}><Button variant="outlined" color="default" style={{ marginLeft: '15px' }}>
                                    Cancel
                                </Button></Link>
                            </Box>

                        </Grid>

                    </Grid>
                </CardContent>
            </div>
        )}
        />
    )
};
const SellPairEditActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
)


const ExchangePairTitle = (props) => {
    return props.record ? (
        <ExchangePairField record={props.record} />
    ) : null;
};

const EditSellPair = (props) => {
    return (
        <Edit {...props}
            actions={<SellPairEditActions />}
            title={<ExchangePairTitle />}
        >
            <EditSellPairForm />
        </Edit>
    )
};
export default EditSellPair;