import React, { FC } from 'react';
import { Link } from 'react-admin';

import AuthorNameField from './AuthorNameField';

const CustomerLinkFieldEvents = props =>
    props.record ? (
        <Link to={`/users/${props.record.user_id}`}>
            <AuthorNameField {...props} />
        </Link>
    ) : null;

CustomerLinkFieldEvents.defaultProps = {
    source: 'customer_id',
    addLabel: true,
};

export default CustomerLinkFieldEvents;
