import simpleRestProvider from "ra-data-simple-rest";
import compose from "recompose/compose";
import api_url from "../ApiUrl";
import JWT from "jsonwebtoken";
import { Resource, fetchUtils } from "react-admin";
import addSearchMethod from "./addSearchMethod";

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    let apitoken = JWT.sign({ url: url }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
        algorithm: "HS512",
    });
    options.headers.set("xelxtoken", apitoken);
    let login_token = localStorage.getItem("auth");
    options.headers.set("Authorization", `Bearer ${login_token}`);
    return fetchUtils.fetchJson(url, options);
};

const restProvider = compose(
    addSearchMethod
)(simpleRestProvider(api_url(), fetchJson));

const delayedDataProvider = new Proxy(restProvider, {
    get: (target, name, self) => {
        if (name === "then") {
            return self;
        }
        return (resource, params) => {
            return new Promise((resolve) =>
                setTimeout(() => resolve(restProvider[name](resource, params)), 200)
            );
        };
    },
});

export default delayedDataProvider;
