import { Fragment } from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
//import { makeStyles } from '@material-ui/core/styles';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const PriceStoreBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('settings', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('settings', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const PriceStoreListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('settings', 'export') == 'ok') ? true : false} />
)

const PriceStoreFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Markets" source="pair_0" defaultValue="" />
    </Filter>
);

// const newStyle = makeStyles({
//     tdcolor: { color: '#4f3cc9' }
// });

const PriceStore = (props) => {
    return (
        <List
            {...props}
            sort={{ field: 'pair_0', order: 'ASC' }}
            actions={<PriceStoreListActions />}
            filters={<PriceStoreFilter />}
            bulkActionButtons={<PriceStoreBulkActionButtons />}
            pagination={<CustomPagination />}  
            title="Exchange rate"          
        >
            <Datagrid className="lastOrdersColumn">
                <TextField source="pair_0" label="Markets" />
                <TextField source="last_price_1" label="Last price" />
                <TextField source="broker_2" label="Brokers" />
                <TextField source="last_price_3" label="Last price" />
                <TextField source="broker_4" label="Brokers" />
                <TextField source="last_price_5" label="Last price" />
                <TextField source="broker_6" label="Brokers" />
                {/* <TextField source="last_price_7" label="Last price" /> */}
                {/* <TextField source="broker_8" label="Brokers" /> */}
            </Datagrid>
        </List>
    )
};
export default PriceStore;