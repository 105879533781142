import { Fragment } from 'react';
import {
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    BooleanField,
    required,
    SelectInput
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import BestPriceIcon from './BestPriceIcon';
import PaymentMode from './PaymentMode';

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            return_value = 'ok';
        }
    });
    return return_value;
}
const ExchangePairBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('markets', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('markets', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const ExchangePairActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('markets', 'export') == 'ok') ? true : false} />
)

const ExchangePairFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Markets" source="pair" defaultValue="" />
    </Filter>
);


const ExchangeCurrencyForm = props => (
    <RowForm {...props}>
        <TextInput source="pair" label="Markets" validate={required()} style={{ width: '120px !important' }} />
        <TextField source="course_price" autoFocus label="Course price" validate={required()} style={{ width: '120px !important' }} />
        <TextField source="process_fee" label="Process fee" validate={required()} style={{ width: '120px !important' }} />
        <TextField source="network_fee" label="Network fee" validate={required()} style={{ width: '120px !important' }} />
        <TextInput source="orginal_fee_alter" label="Fees" validate={required()} />
        <TextInput source="bonus_alter" label="Bonus" validate={required()} />
        <TextField source="xml_rate" label="Receivable" validate={required()} />
        <SelectInput source="best_price" choices={bestprice_choices} label="Best price" validate={required()} />
        <SelectInput source="system_process_by" choices={payments_choices} label="Payment mode" validate={required()} />
        <TextInput source="grate_alter" label="Timers" validate={required()} className="timerWidth" />
        <SelectInput source="status" choices={status_choices} label="Status" validate={required()} className="statusWidth" />
    </RowForm>
);

const newStyle = makeStyles({
    tdcolor: { color: '#4f3cc9' }
});

const payments_choices = [
    { id: 'Manual', name: 'Manual' },
    { id: 'Semi-automatic', name: 'Semi-automatic' },
    { id: 'Automatic', name: 'Automatic' }]

const bestprice_choices = [
    { id: 'High price', name: 'High price' },
    { id: 'Low price', name: 'Low price' },
    { id: 'No price', name: 'No price' }]

const orgfeetype_choices = [
    { id: 'Charge', name: 'Charge' },
    { id: 'Bonus', name: 'Bonus' }]

const status_choices = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }];

const ExchangePair = (props) => {
    const classes = newStyle();
    //console.log(props.ExchangePair);
    return (
        <List
            {...props}
            sort={{ field: 'pair', order: 'ASC' }}
            hasCreate
            empty={false}
            actions={<ExchangePairActions />}
            filters={<ExchangePairFilter />}
            bulkActionButtons={<ExchangePairBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Exchange pair"
            className='exchange_pair'
        >
            {/* <Datagrid rowClick="edit"> */}
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                createForm={<ExchangeCurrencyForm />}
                editForm={<ExchangeCurrencyForm />}
            >
                <TextField source="pair" label="Markets" style={{ width: '120px !important' }} />
                <TextField source="course_price" label="Course price" style={{ width: '120px !important' }} />
                <TextField source="process_fee" label="Process fee" style={{ width: '120px !important' }} />
                <TextField source="network_fee" label="Network fee" style={{ width: '120px !important' }} />
                <TextField source="orginal_fee" label="Fees" />
                <TextField source="bonus" label="Bonus" />
                <TextField source="xml_rate" label="Receivable" />
                <BestPriceIcon source="best_price" label="Price" />
                <PaymentMode source="system_process_by" label="Mode" />
                <TextField source="grate" label="Timers" />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default ExchangePair;