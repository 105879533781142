import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UpdateOutlinedIcon from "@material-ui/icons/UpdateOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import MoneyOffOutlinedIcon from "@material-ui/icons/MoneyOffOutlined";
import SettingsBackupRestoreOutlinedIcon from "@material-ui/icons/SettingsBackupRestoreOutlined";
import { Tooltip, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const StatusField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.status == 'completed') ?
                <Tooltip title="Completed"><CheckCircleOutlineIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(34, 154, 22)'}}
                /></Tooltip> : ((record.status == 'refunded') ? <Tooltip title="Refunded"><SettingsBackupRestoreOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(0, 123, 85)'}}
                /></Tooltip> : ((record.status == 'hold') ? <Tooltip title="Hold"><MoneyOffOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(99, 115, 129)'}}
                /></Tooltip> : ((record.status == 'expired') ? <Tooltip title="Expired"><UpdateOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(183, 33, 54)'}}
                /></Tooltip> : ((record.status == 'waiting') ? <Tooltip title="Waiting"><MoreHorizOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(183, 129, 3)'}}
                /></Tooltip> : <Tooltip title="Processing"><SyncOutlinedIcon
                    className={classes.avatar}
                    record={record}
                    size={size}
                    style={{color:'rgb(12, 83, 183)'}}
                /></Tooltip>))))}
        </div>
    ) : null;
};

export default StatusField;
