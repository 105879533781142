import { Fragment } from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    DateField,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
//import { makeStyles } from '@material-ui/core/styles';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const BuyRateBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('settings', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {/* {(idRoleAllow('settings', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''} */}
    </Fragment>
);

const BuyRateListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('settings', 'export') == 'ok') ? true : false} />
)

const BuyRateFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Markets" source="pair" defaultValue="" />
    </Filter>
);

// const newStyle = makeStyles({
//     tdcolor: { color: '#4f3cc9' }
// });

const Buyrate = (props) => {
    return (
        <List
            {...props}
            sort={{ field: 'pair', order: 'ASC' }}
            actions={<BuyRateListActions />}
            filters={<BuyRateFilter />}
            bulkActionButtons={<BuyRateBulkActionButtons />}
            pagination={<CustomPagination />}
            title="Buy rate"
        >
            <Datagrid className="lastOrdersColumnForex">
                <TextField source="pair" label="Markets" />
                <TextField source="last_price_alter" label="Last price" />
                <TextField source="broker" label="Brokers" />
                <DateField source="last_updated" label="Update time" showTime />
            </Datagrid>
        </List>
    )
};
export default Buyrate;