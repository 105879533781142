import React, { FC } from 'react';
import EmailStatusField from './EmailStatusField';

const UserEmailStatus = props =>
    props.record ? (
        <EmailStatusField {...props} />
    ) : null;

UserEmailStatus.defaultProps = {
    source: 'latest_news',
    addLabel: true,
};

export default UserEmailStatus;
