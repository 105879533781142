import React, { FC } from 'react';
import { Link } from 'react-admin';

import StatusField from './StatusField';

const OrderStatus = props =>
    props.record ? (
            <StatusField {...props} />
    ) : null;

    OrderStatus.defaultProps = {
    source: 'status',
    addLabel: true,
};

export default OrderStatus;
