import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
    },
}));


const FeeTypeField = ({ record, size }) => {
    const classes = useStyles();
    return record ? (
        <div className={classes.root}>
            {(record.network_fee > 0) ? <HttpsOutlinedIcon size="small" style={{ fontSize: '18' }} /> : <AddCircleOutlineIcon size="small" style={{ fontSize: '18' }} />}
        </div>
    ) : null;
};

export default FeeTypeField;
